'use client';
import {GoogleTagManager, GoogleAnalytics} from '@next/third-parties/google';
import Script from 'next/script';
import {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {COOKIE_CONSENT_COOKIE} from '@/constants/cookies';
import {ADOBE_CDP_SCRIPT} from '@/constants/flags';
import {
  ADOBE_TAG_URL,
  GA_ID,
  GTM_ID,
  HOTJAR_ID,
  HOTJAR_SV,
} from '@/constants/global';
import {FlagsType} from '@/context/FlagsContext';
import {useRemoveAllCookies} from '@/hooks/useRemoveAllCookies';

interface CookieConsentScriptsProps {
  isIframeEmbedded: boolean;
  flags: FlagsType;
  browserTimingHeader: string;
}

const CookieConsentScripts = ({
  isIframeEmbedded,
  flags,
  browserTimingHeader,
}: CookieConsentScriptsProps) => {
  const [cookies] = useCookies([COOKIE_CONSENT_COOKIE]);
  const {removeAllCookies} = useRemoveAllCookies();

  const hasRevokedConsent = cookies[COOKIE_CONSENT_COOKIE] === 'false';
  const loadAdobeCdp = flags[ADOBE_CDP_SCRIPT] === 'on';
  const hotjarScriptEnabled = !isIframeEmbedded;

  useEffect(() => {
    if (hasRevokedConsent) {
      removeAllCookies();
    }
  }, [hasRevokedConsent, removeAllCookies]);

  return hasRevokedConsent ? (
    <>
      <GoogleAnalytics gaId={GA_ID} />
      <Script id='denied-consent'>
        {`
                                    gtag('consent', 'update', {
                                      'ad_storage': 'denied',
                                      'ad_user_data': 'denied',
                                      'ad_personalization': 'denied',
                                      'analytics_storage': 'denied'
                                  });
                                `}
      </Script>
    </>
  ) : (
    <>
      <GoogleTagManager gtmId={GTM_ID} />
      <Script
        id='browserTimingHeader'
        type='text/javascript'
        // @ts-ignore
        dangerouslySetInnerHTML={{
          __html: browserTimingHeader,
        }}
        strategy='afterInteractive'
      />
      {loadAdobeCdp ? (
        <Script
          id='adobe_tag'
          src={ADOBE_TAG_URL}
          strategy='lazyOnload'
          defer
        />
      ) : null}
      {hotjarScriptEnabled ? (
        <Script id='hotjar' strategy='lazyOnload' defer>
          {`(function(h,o,t,j,a,r){
                                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                        h._hjSettings={hjid:${HOTJAR_ID},hjsv:${HOTJAR_SV}};
                                        a=o.getElementsByTagName('head')[0];
                                        r=o.createElement('script');r.async=1;
                                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                        a.appendChild(r);
                                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}
        </Script>
      ) : null}
    </>
  );
};

export default CookieConsentScripts;
