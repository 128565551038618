'use client';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {FC, ReactNode} from 'react';
import {Cookies, CookiesProvider} from 'react-cookie';
import ToastContainerHolder from '@/components/ToastContainer';
import {REGISTRATION_WALL} from '@/constants/flags';
import {ABExperimentProvider} from '@/context/ABExperimentContext';
import {CountActivationsProvider} from '@/context/CountActivationsContext';
import {FlagsProvider, FlagsType} from '@/context/FlagsContext';
import {UserAgentProvider} from '@/context/UserAgentContext';
import {UserAgent} from '@/models/userAgent';
import {RegistrationWallConfig} from '@/services/registrationWall';

interface IProviderProps {
  children: ReactNode;
  userAgent: UserAgent;
  cookies: any;
  flags: FlagsType;
  isNewVersion: boolean;
  registrationConfig: RegistrationWallConfig;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const Provider: FC<IProviderProps> = ({
  children,
  userAgent,
  cookies: universalCookies,
  flags,
  isNewVersion,
  registrationConfig,
}) => (
  <QueryClientProvider client={queryClient}>
    <CookiesProvider cookies={new Cookies(universalCookies)}>
      <UserAgentProvider userAgent={userAgent}>
        <FlagsProvider flags={flags}>
          <ToastContainerHolder />
          <ABExperimentProvider isNewVersion={isNewVersion}>
            <CountActivationsProvider
              config={registrationConfig}
              isFlagEnabled={flags[REGISTRATION_WALL] === 'on'}>
              {children}
            </CountActivationsProvider>
          </ABExperimentProvider>
        </FlagsProvider>
      </UserAgentProvider>
    </CookiesProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default Provider;
