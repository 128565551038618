'use client';
import {usePathname} from 'next/navigation';
import {useEffect} from 'react';
import {useClarityInit} from '@/hooks/useClarityInit';
import useClientJSFingerprint from '@/hooks/useClientJSFingerprint';
import {getClarity} from '@/services/clarity';
import {pageView} from '@/utils/gtm';

interface ClarityProps {
  isClarityScriptEnabled: boolean;
}

const AppTracking = ({isClarityScriptEnabled}: ClarityProps) => {
  useClientJSFingerprint();
  useClarityInit(isClarityScriptEnabled);

  const clarity = getClarity();
  const pathname = usePathname();

  // Fire pageview with current url when page first loads
  useEffect(() => {
    pageView(window?.location?.href);
  }, []);

  useEffect(() => {
    if (pathname) {
      pageView(pathname);
      if (isClarityScriptEnabled) {
        clarity?.setEvent('pageview');
      }
    }
  }, [pathname, isClarityScriptEnabled, clarity]);

  return null;
};

export default AppTracking;
