'use client';
import {NavbarV2, useIsSmallDesktop} from '@prescriberpoint/ui';
import {FC, useState, useEffect, useMemo, useRef, Dispatch} from 'react';
import {useIsClient, useOnClickOutside} from 'usehooks-ts';
import AuthNavigation from '@/components/AuthNavigation';
import SearchBar from '@/components/Searchbar';
import {
  CASE_MANAGEMENT,
  SAMPLES_APP,
  SHOW_AI_CHATBOT_LINK,
} from '@/constants/flags';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {useFlag} from '@/context/FlagsContext';
import {useMobileContentContext} from '@/context/MobileContentProvider';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useCurrentSlug} from '@/hooks/useCurrentSlug';
import {useRouter} from '@/hooks/useRouter';
import useUser, {defaultUser} from '@/hooks/useUser';
import {useCurrentPerson} from '@/queries/useCurrentPerson';
import {useHCPUser} from '@/queries/useHCPUser';

interface CustomNavbarProps {
  isSidebarOpen?: boolean;
  setSidebarOpen?: Dispatch<boolean>;
  nhOn?: boolean;
  hideRightMenu?: boolean;
}

const CustomNavbar: FC<CustomNavbarProps> = ({
  isSidebarOpen = false,
  setSidebarOpen,
  nhOn = false,
  hideRightMenu,
}) => {
  const isSmallDesktop = useIsSmallDesktop();
  const {isMobileOrTablet} = useUserAgentContext();
  const isClient = useIsClient();
  const user = useUser();
  const {slug} = useCurrentSlug();
  const [showUser, setShowUser] = useState(false);
  const {isMenuOpen, toggleMenuOpen} = useMobileContentContext();
  const showCaseManagementFlag = useFlag(CASE_MANAGEMENT);
  const showAILink = useFlag(SHOW_AI_CHATBOT_LINK);
  const showSamples = useFlag(SAMPLES_APP);
  const {data: person} = useCurrentPerson();
  const {data: hcpUser} = useHCPUser();
  const router = useRouter();

  const navbarRef = useRef(null);
  useOnClickOutside(navbarRef, () => {
    if (isSidebarOpen && setSidebarOpen) {
      setSidebarOpen(false);
    }
  });

  const isHcp = useMemo(() => !!hcpUser && hcpUser !== true, [hcpUser]);
  const isRep = useMemo(() => !!person && person.role === 'Rep', [person]);

  const samplesLink = useMemo(() => {
    const isHome = router.pathname.includes('home');
    const url = isHome ? '/sample-store' : `/sample-store/${slug}`;
    return new URL(url, PROTOCOL_HOSTNAME).toString();
  }, [slug, router]);

  const showCaseManagement = useMemo(() => {
    const isPPTUser = user.email?.includes('@prescriberpoint.com');
    return showCaseManagementFlag && isHcp && isPPTUser;
  }, [showCaseManagementFlag, isHcp, user]);

  useEffect(() => {
    setShowUser(true);
  }, []);

  if (nhOn) {
    return <AuthNavigation />;
  }

  return (
    <div
      className='z-30 border border-x-0 border-t-0 border-solid border-neutral-light lg:border-0'
      ref={navbarRef}>
      <NavbarV2
        isMenuOpen={isMenuOpen}
        isMobileOrTablet={isClient && (isSmallDesktop || isMobileOrTablet)}
        SearchBarComponent={SearchBar}
        toggleMenuOpen={toggleMenuOpen}
        user={showUser ? {...user, role: person?.role ?? ''} : defaultUser}
        onClickSidebar={
          setSidebarOpen ? () => setSidebarOpen(!isSidebarOpen) : undefined
        }
        showAILink={showAILink}
        showSamples={showSamples}
        samplesLink={samplesLink}
        isSamplesUser={isHcp || isRep}
        isRep={isRep}
        showCasesLink={showCaseManagement}
        hideRightMenu={hideRightMenu}
      />
    </div>
  );
};

export default CustomNavbar;
