import dynamic from 'next/dynamic';
import {createContext, ReactNode, useContext, useMemo} from 'react';
import {
  initializeActivationCount,
  useCountActivations,
  RegistrationWallActivations,
  ActivationType,
} from '@/hooks/useCountActivations';
import {RegistrationWallConfig} from '@/services/registrationWall';

const CreateAccountModal = dynamic(
  () => import('@/components/CreateFreeAccountModal'),
  {
    ssr: false,
  },
);

interface ICountActivationsContext {
  activationCount: RegistrationWallActivations;
  incrementActivation: (
    _type: ActivationType,
    _afterIncrement: () => void,
  ) => void;
  getActivationsLeft: (_type: ActivationType) => number;
  openActivationModal: (_type?: ActivationType) => void;
}

const defaultActivationContext: ICountActivationsContext = {
  activationCount: initializeActivationCount(),
  incrementActivation: () => {},
  getActivationsLeft: () => 0,
  openActivationModal: (_type?: ActivationType) => {},
};

const CountActivationsContext = createContext<ICountActivationsContext>(
  defaultActivationContext,
);

export const useCountActivationsContext = () =>
  useContext(CountActivationsContext);

interface CountActivationsProviderProps {
  children: ReactNode;
  config: RegistrationWallConfig;
  isFlagEnabled: boolean;
}

export const CountActivationsProvider = ({
  children,
  config,
  isFlagEnabled,
}: CountActivationsProviderProps) => {
  const {
    activationCount,
    incrementActivation,
    isActivationModalOpen,
    closeActivationModal,
    activationType,
    getActivationsLeft,
    openActivationModal,
  } = useCountActivations(config, isFlagEnabled);

  const activationContextValues = useMemo(
    () => ({
      activationCount,
      incrementActivation,
      getActivationsLeft,
      openActivationModal,
    }),
    [
      activationCount,
      incrementActivation,
      getActivationsLeft,
      openActivationModal,
    ],
  );

  return (
    <CountActivationsContext.Provider value={activationContextValues}>
      {isFlagEnabled ? (
        <CreateAccountModal
          isOpen={isActivationModalOpen}
          closeModal={closeActivationModal}
          type={activationType}
        />
      ) : null}
      {children}
    </CountActivationsContext.Provider>
  );
};
