import {useCallback, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {
  ACTIVATION_COUNT_COOKIE,
  ACCESS_TOKEN_COOKIE,
} from '@/constants/cookies';
import {RegistrationWallConfig} from '@/services/registrationWall';

const COUNT_EXPIRATION_TIME = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

export interface RegistrationWallActivations {
  coverage: number;
  financialAssistance: number;
  drugInteractions: number;
  resources: number;
  ai: number;
  expirationDate?: Date;
}

export type ActivationType = keyof Omit<
  RegistrationWallActivations,
  'expirationDate'
>;

interface CookieStoreChangeEvent extends Event {
  changed: {name: string; value: string}[];
  deleted: {name: string; value: string}[];
}

const getCountExpirationDate = () =>
  new Date(Date.now() + COUNT_EXPIRATION_TIME);

export const initializeActivationCount = () => ({
  coverage: 0,
  financialAssistance: 0,
  drugInteractions: 0,
  resources: 0,
  ai: 0,
  expirationDate: getCountExpirationDate(),
});

export const getTotalActivations = (
  activationCount: RegistrationWallActivations,
) => {
  const sumableActivations = Object.entries(activationCount).filter(
    ([key, value]) => key !== 'expirationDate' && typeof value === 'number',
  );
  return sumableActivations.reduce((acc, [_, value]) => acc + value, 0);
};

/**
 * We need to keep track of a series of events.
 * This hook will help us to keep track of the number of times an event has occurred.
 *
 * Events:
 *
 * coverage: counts the number of times a user selects an insures
 *
 * financialAssistance: counts the number of times a user downloads a form
 *
 * drugInteractions: counts the number of times a user selects a drug to research
 *
 * resources: counts the number of times a user clicks on a resource on the brand page
 *
 * AI: Not supported for now (ai comes from a script)
 */
export const useCountActivations = (
  config: RegistrationWallConfig,
  isFlagEnabled?: boolean,
) => {
  // Use this hook inside CountActivationsContext. It will sync the activation count with the cookie
  const [cookies, setCookie] = useCookies([
    ACTIVATION_COUNT_COOKIE,
    ACCESS_TOKEN_COOKIE,
  ]);
  const [activationCount, setActivationCount] =
    useState<RegistrationWallActivations>(
      cookies[ACTIVATION_COUNT_COOKIE] ?? initializeActivationCount(),
    );
  const isLogged = !!cookies[ACCESS_TOKEN_COOKIE];
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [activationType, setActivationType] = useState<ActivationType>();

  /**
   * If the user is logged in, the afterIncrement function will be executed immediately.
   * Otherwise, the activation count will be incremented and the afterIncrement function will be executed
   * if the user has not reached the limit of interactions.
   */
  const incrementActivation = useCallback(
    (type: ActivationType, afterIncrement: () => void) => {
      if (!isLogged && isFlagEnabled) {
        const currentCount = activationCount[type];
        const newCount =
          typeof currentCount === 'number' ? currentCount + 1 : 1;
        const expirationDate = activationCount.expirationDate
          ? new Date(activationCount.expirationDate)
          : getCountExpirationDate();
        const newActivationCount = {
          ...activationCount,
          [type]: newCount,
          expirationDate,
        };
        setCookie(ACTIVATION_COUNT_COOKIE, newActivationCount, {
          expires: expirationDate,
          path: '/',
        });
        setActivationCount(newActivationCount);
        const totalActivations = getTotalActivations(newActivationCount);
        const hasReachedLimit =
          config[type] <= newCount ||
          totalActivations >= config.maxInteractions;

        setActivationType(type);

        if (hasReachedLimit) {
          setIsActivationModalOpen(true);
          if (
            config[type] === newCount ||
            totalActivations === config.maxInteractions
          ) {
            afterIncrement();
          }
        } else {
          afterIncrement();
        }
      } else afterIncrement();
    },
    [
      activationCount,
      setCookie,
      setActivationCount,
      config,
      isLogged,
      isFlagEnabled,
    ],
  );

  const getActivationsLeft = useCallback(
    (type: ActivationType) => Math.max(0, config[type] - activationCount[type]),
    [activationCount, config],
  );

  const openActivationModal = useCallback((type?: ActivationType) => {
    setIsActivationModalOpen(true);
    setActivationType(type);
  }, []);

  const closeActivationModal = () => setIsActivationModalOpen(false);

  useEffect(() => {
    // This effects handles the ai cookie change event only
    if ('cookieStore' in window) {
      const cookieStore = window.cookieStore;
      const changeHandler = (event: CookieStoreChangeEvent) => {
        const activationCookie = event.changed.find(
          (cookie) => cookie.name === ACTIVATION_COUNT_COOKIE,
        );
        if (activationCookie) {
          const decodedValue = decodeURIComponent(activationCookie.value);
          const newActivationCount = JSON.parse(decodedValue);
          if (
            activationCount.ai !== newActivationCount.ai ||
            getTotalActivations(newActivationCount) >= config.maxInteractions
          ) {
            setActivationCount(newActivationCount);
            // This opens the modal when the user reaches the limit of interactions or has 1 interaction left
            if (newActivationCount.ai >= config.ai - 1) {
              setIsActivationModalOpen(true);
              setActivationType('ai');
            }
          }
        }
      };
      cookieStore.addEventListener('change', changeHandler);
      return () => cookieStore.removeEventListener('change', changeHandler);
    }
  }, [activationCount, cookies, config]);

  return {
    activationCount,
    incrementActivation,
    isActivationModalOpen,
    closeActivationModal,
    activationType,
    getActivationsLeft,
    openActivationModal,
  };
};
