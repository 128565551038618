'use client';
import {useIsMobileOrTablet} from '@prescriberpoint/ui';
import clsx, { ClassValue } from 'clsx';
import dynamic from 'next/dynamic';
import {usePathname} from 'next/navigation';
import {FC, useMemo, useState} from 'react';
import Navbar from '@/components/CustomNavbar';
import Disclaimer from '@/components/Disclaimer';
import {NAVBAR_ID} from '@/constants';
import {SHOW_V2_PPT_DESIGN} from '@/constants/flags';
import {useFlag, useMobileContentContext} from '@/context';

const MobileContentProvider = dynamic(() =>
  import('@/context/MobileContentProvider').then(
    (m) => m.MobileContentProvider,
  ),
);

const Footer = dynamic(() => import('@/components/Footer'));

interface MainLayoutProps {
  children: React.ReactNode;
  maintenanceMode?: boolean;
  footerClassName?: ClassValue;
}

const WHITE_PAGES = [
  {
    base: 'financial-assistance',
    inMobileWhite: true,
  },
  {
    base: 'financial-assistance',
    sub: true,
  },
  {
    base: 'coverage-new',
    inMobileWhite: true,
  },
  {
    base: 'coverage-new',
    sub: true,
  },
];

const MainLayout: FC<MainLayoutProps> = ({children, maintenanceMode, footerClassName}) => {
  const {isMenuOpen} = useMobileContentContext();
  const isMobileOrTablet = useIsMobileOrTablet();
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const pathName = usePathname();

  const isWhitePage = useMemo(
    () =>
      WHITE_PAGES.some((page) => {
        if (page.inMobileWhite && isMobileOrTablet) {
          return pathName.includes(page.base);
        }
        if (page.inMobileWhite && !isMobileOrTablet) {
          return false;
        }
        if (page.sub) {
          return new RegExp(`/${page.base}/[^/]+`).test(pathName);
        }
        return pathName.includes(page.base);
      }),
    [pathName, isMobileOrTablet],
  );

  const isWhiteFooter = useMemo(
    () => WHITE_PAGES.some((page) => pathName.includes(page.base)),
    [pathName],
  );

  return (
    <div
      className={clsx('relative flex h-fit min-h-screen flex-col', {
        'overflow-hidden': isMenuOpen,
      })}>
      <div
        id={NAVBAR_ID}
        className='sticky top-0 z-50 w-full bg-white xs:z-max'>
        <Disclaimer
          showDisclaimer={showDisclaimer}
          setShowDisclaimer={setShowDisclaimer}
        />
        <Navbar hideRightMenu={maintenanceMode} />
      </div>

      <div
        className={clsx(
          'flex-1',
          showNewDesign && !isWhitePage ? 'bg-neutral-lighter-alt' : 'bg-white',
        )}>
        {children}
      </div>
      <Footer className={clsx(isWhiteFooter ? 'bg-white' : null, footerClassName)} />
    </div>
  );
};

const MainLayoutWrapper: FC<MainLayoutProps> = ({
  children,
  maintenanceMode,
  footerClassName,
}) => (
  <MobileContentProvider>
    <MainLayout maintenanceMode={maintenanceMode} footerClassName={footerClassName}>{children}</MainLayout>
  </MobileContentProvider>
);

export default MainLayoutWrapper;
