'use client';
import {useRef} from 'react';
import {CEVENT_HP_SESSION_SUS} from '@/constants/gtm';
import {customEvent} from '@/utils/gtm';

const HoneypotInput = () => {
  const interactionTriggeredRef = useRef(false);

  const handleInteraction = () => {
    if (interactionTriggeredRef.current) {
      return;
    }
    customEvent(CEVENT_HP_SESSION_SUS);
    interactionTriggeredRef.current = true;
  };

  return (
    <input
      id='honeypot-input'
      type='text'
      name='honeypot'
      style={{display: 'none'}}
      onChange={handleInteraction}
      onClick={handleInteraction}
      onFocus={handleInteraction}
      onKeyDown={handleInteraction}
    />
  );
};

export default HoneypotInput;
