'use client';

import {Link, Text} from '@prescriberpoint/ui';
import {Wrench} from 'lucide-react';
import MainLayout from '@/layouts/MainLayout';

const MaintenancePage = () => (
  <MainLayout maintenanceMode>
    <div className='flex w-full items-center justify-center px-10 pt-20 text-center md:px-0'>
      <div className='w-full max-w-lg flex-col items-center justify-center md:w-1/2'>
        <div className='inline-flex rounded-full !bg-warning-fill p-4'>
          <Wrench
            className='size-[30px] text-status-warning'
            strokeWidth={1.6}
          />
        </div>
        <div className='mb-4 flex justify-center' />
        <div className='flex flex-col space-y-2'>
          <Text tag='h1' as='headline-md' weight='bold'>
            System Maintenance in Progress
          </Text>
          <Text as='title-md' weight='regular' className='leading-7'>
            We are currently performing scheduled maintenance to improve our
            services. We expect to be back online shortly. Thank you for your
            patience and understanding.
          </Text>

          <Text as='body-md' weight='regular' className='!mt-8'>
            If you need immediate assistance, please contact our support team at{' '}
            <Link
              label='support@prescriberpoint.com'
              href='mailto:support@prescriberpoint.com'
              textClassName='!text-base !text-theme-neutral'
            />{' '}
            or reach out via chat.
          </Text>
        </div>
      </div>
    </div>
  </MainLayout>
);

export default MaintenancePage;
